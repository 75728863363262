import {
  getToken,
  setToken,
  removeToken,
  setUsuario,
  getUsuario,
  removeUsuario
} from "@/utils/auth";
import UserApi from "@/api/user";
import router from "@/router";

const getDefaultState = () => {
  return {
    token: getToken(),
    usuario: getUsuario(),
    modulos: [],
    filter: "all",
    search: "",
    emailRecover: "", // to recover password
    menuActive: "inicio"
  };
};

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    filter: (state) => state.filter,
    search: (state) => state.search
  },
  mutations: {
    RESET_STATE: (state) => {
      Object.assign(state, getDefaultState());
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER: (state, usuario) => {
      state.usuario = usuario;
    },
    SET_MODULOS: (state, modulos) => {
      state.modulos = modulos;
    },
    SET_FILTER: (state, filter) => {
      state.filter = filter;
    },
    SET_SEARCH: (state, search) => {
      state.search = search;
    },
    CLEAN_FILTERS: (state) => {
      state.filter = "all";
      state.search = "";
    },
    SET_EMAIL_RECOVER: (state, email) => {
      state.emailRecover = email;
    },
    SET_MENU_ACTIVE: (state, activeMenu) => {
      state.menuActive = activeMenu;
    }
  },

  actions: {
    login: async ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        UserApi.login(payload)
          .then((response) => {
            const { data } = response;
            const [{ token, usuario }] = data;
            commit("SET_TOKEN", token);
            commit("SET_USER", usuario);
            setToken(token);
            setUsuario(usuario);

            router.push({ name: "mainmenu" });
            resolve(); // return empty
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    forgot: async ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        UserApi.forgot(payload)
          .then(() => {
            commit("SET_EMAIL_RECOVER", payload.correo);
            router.push({ name: "recoverpassword" });
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    recover: async (_, payload) => {
      return new Promise((resolve, reject) => {
        UserApi.recover(payload)
          .then(() => {
            router.push({ name: "login" });
            resolve(); // return empty
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getModulos({ commit }) {
      return new Promise((resolve, reject) => {
        UserApi.getModulos()
          .then((response) => {
            const { data } = response;
            const modulos = data[0];
            const estadoUsuario = data[1]?.idestado;

            if (estadoUsuario !== "1") {
              reject(new Error("No tiene el estado vigente"));
            }

            const menuInicio = {
              ruta: "/menu"
            };
            const menuMicuenta = {
              ruta: "/micuenta"
            };

            modulos.push(menuInicio);
            modulos.push(menuMicuenta);

            commit("SET_MODULOS", modulos);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
            commit("RESET_STATE");
          });
      });
    },

    logout({ commit }, payload) {
      return new Promise((resolve, reject) => {
        UserApi.logout(payload)
          .then(() => {
            removeToken();
            removeUsuario();
            // must remove auth data first
            commit("RESET_STATE");
            router.push({ name: "login" });
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    setUsuario({ commit }, payload) {
      commit("SET_USER", payload);
      setUsuario(payload);
    },

    // remove token
    resetToken({ commit }) {
      return new Promise((resolve) => {
        removeToken();
        removeUsuario();
        // must remove auth data first
        commit("RESET_STATE");
        resolve();
      });
    },

    setFilter: ({ commit }, payload) => {
      commit("SET_FILTER", payload);
    },
    setSearch: ({ commit }, payload) => {
      commit("SET_SEARCH", payload);
    },
    cleanFilters: ({ commit }) => {
      commit("CLEAN_FILTERS");
    },
    setMenuActive: ({ commit }, payload) => {
      commit("SET_MENU_ACTIVE", payload);
    }
  }
};
