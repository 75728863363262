export default {
  namespaced: true,

  state: {
    provincia: "",
    tipo: "",
    tipoDistrito: "",
    distrito: "",
    search: "",
    edad: ""
  },

  getters: {
    provincia: (state) => state.provincia,
    tipo: (state) => state.tipo,
    tipoDistrito: (state) => state.tipoDistrito,
    distrito: (state) => state.distrito,
    search: (state) => state.search,
    edad: (state) => state.edad
  },

  mutations: {
    SET_PROVINCIA: (state, provincia) => {
      state.provincia = provincia;
    },
    SET_TIPO_PROVINCIA: (state, tipo) => {
      state.tipo = tipo;
    },
    SET_TIPO_DISTRITO: (state, tipo) => {
      state.tipoDistrito = tipo;
    },
    SET_DISTRITO: (state, distrito) => {
      state.distrito = distrito;
    },
    SET_EDAD: (state, edad) => {
      state.edad = edad;
    },
    SET_SEARCH: (state, search) => {
      state.search = search;
    },
    CLEAN_FILTERS: (state) => {
      state.provincia = "";
      state.distrito = "";
      state.search = "";
      state.edad = "";
      state.tipo = "";
      state.tipoDistrito = "";
    }
  },

  actions: {
    setprovincia: ({ commit }, payload) => {
      commit("SET_PROVINCIA", payload);
    },
    setdistrito: ({ commit }, payload) => {
      commit("SET_DISTRITO", payload);
    },
    setTipoProvincia: ({ commit }, payload) => {
      commit("SET_TIPO_PROVINCIA", payload);
    },
    setTipoDistrito: ({ commit }, payload) => {
      commit("SET_TIPO_DISTRITO", payload);
    },
    setsearch: ({ commit }, payload) => {
      commit("SET_SEARCH", payload);
    },
    setEdad: ({ commit }, payload) => {
      commit("SET_EDAD", payload);
    },
    cleanFilters: ({ commit }) => {
      commit("CLEAN_FILTERS");
    }
  }
};
