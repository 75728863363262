export default {
  namespaced: true,

  state: {
    search1: "",
    search2: ""
  },

  getters: {
    search1: (state) => state.search1,
    search2: (state) => state.search2
  },

  mutations: {
    SET_SEARCH1: (state, search1) => {
      state.search1 = search1;
    },
    SET_SEARCH2: (state, search2) => {
      state.search2 = search2;
    },
    CLEAN_FILTERS: (state) => {
      state.search1 = "";
      state.search2 = "";
    }
  },

  actions: {
    setSearch1: ({ commit }, payload) => {
      commit("SET_SEARCH1", payload);
    },
    setSearch2: ({ commit }, payload) => {
      commit("SET_SEARCH2", payload);
    },
    cleanFilters: ({ commit }) => {
      commit("CLEAN_FILTERS");
    }
  }
};
