import { createStore } from "vuex";
import getters from "./getters";
import user from "./modules/user";
import parametros from "./modules/parametros";
import ubigeo from "./modules/ubigeo";
import productora from "./modules/productora";
import establecimiento from "./modules/establecimiento";
import tipodocumento from "./modules/tipodocumento";
import tiposeguro from "./modules/tiposeguro";
import paciente from "./modules/paciente";
import personal from "./modules/personal";
import actividad from "./modules/actividad";
import indicador from "./modules/indicador";
import periodoevaluacion from "./modules/periodoevaluacion";
import clasificacionenfermedad from "./modules/clasificacionenfermedad";
import periodo from "./modules/periodo";
import perfil from "./modules/perfil";
import mapa from "./modules/mapa";
import detalleindicador from "./modules/detalleindicador";
import exportar from "./modules/exportar";
import gestantes from "./modules/gestantes";
import ninios from "./modules/ninios";
import mapaPaciente from "./modules/mapaPaciente";
import pacientereporte from "./modules/pacientereporte";
import cantidadPorcentual from "./modules/cantidadPorcentual";
import gestantesNutricional from "./modules/gestantesNutricional";
import graficas from "./modules/graficas";
import graficasEstablecimientos from "./modules/graficasEstablecimientos";
import destinatario from "./modules/destinatario";
import mensajes from "./modules/mensajes";
import programacion from "./modules/programacion";
import grupos from "./modules/grupos";
import padron_nominal from "./modules/padron_nominal";

export default createStore({
  modules: {
    user,
    parametros,
    periodo,
    actividad,
    establecimiento,
    paciente,
    personal,
    productora,
    mapa,
    exportar,
    padron_nominal,
    gestantes,
    ninios,
    destinatario,
    programacion,
    ubigeo,
    graficas,
    mensajes,
    graficasEstablecimientos,
    gestantesNutricional,
    mapaPaciente,
    detalleindicador,
    pacientereporte,
    cantidadPorcentual,
    tipodocumento,
    periodoevaluacion,
    indicador,
    tiposeguro,
    clasificacionenfermedad,
    perfil,
    grupos
  },
  getters
});
