import { createApp } from "vue";
import App from "./App.vue";
import {
  Button,
  message,
  ConfigProvider,
  Form,
  Input,
  Image,
  Menu,
  Dropdown,
  Tabs,
  PageHeader,
  Descriptions,
  Anchor,
  Table,
  Alert,
  Card,
  Select,
  Pagination,
  Modal,
  Tag,
  Row,
  Col,
  DatePicker,
  Layout,
  Radio,
  List,
  Upload,
  Result,
  Timeline,
  Skeleton,
  TimePicker,
  Tooltip,
  Tree,
  Badge,
  Switch,
  Spin,
  Checkbox,
  Breadcrumb,
  Carousel
} from "ant-design-vue";
import "./styles/index.less";
import "./styles/tailwind.css";

import router from "./router";
import store from "./store";

import VueChartkick from "vue-chartkick";
import "chartkick/chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { GlobalCmComponent } from "codemirror-editor-vue3";
import loader from "vue-ui-preloader";
import print from "vue3-print-nb";
import { Chart } from "chart.js";

Chart.register(ChartDataLabels);
Chart.defaults.set("plugins.datalabels", {
  backgroundColor: function (context) {
    return context.dataset.backgroundColor;
  },
  borderRadius: 4,
  color: "white",
  font: {
    weight: "bold"
  },
  // formatter: Math.round,
  padding: 3
});

import ECharts from "vue-echarts";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueChartkick);
app.use(print);
app.component("v-chart", ECharts);

// graficos
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart, GraphChart, GaugeChart, PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent
} from "echarts/components";
import { use } from "echarts/core";

use([
  // charts
  LineChart,
  BarChart,
  GraphChart,
  GaugeChart,
  PieChart,
  // necesarios
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent
]);

app.config.productionTip = false;

/* Automatically register components under Button, such as Button.Group */
app.use(Button);
app.use(ConfigProvider);
app.use(Form);
app.use(Input);
app.use(Image);
app.use(Menu);
app.use(Dropdown);
app.use(Tabs);
app.use(PageHeader);
app.use(Descriptions);
app.use(Anchor);
app.use(Table);
app.use(Alert);
app.use(Card);
app.use(Select);
app.use(Pagination);
app.use(Modal);
app.use(Tag);
app.use(Row);
app.use(Col);
app.use(DatePicker);
app.use(Layout);
app.use(Radio);
app.use(List);
app.use(Upload);
app.use(Result);
app.use(Timeline);
app.use(Skeleton);
app.use(TimePicker);
app.use(Tooltip);
app.use(Tree);
app.use(Badge);
app.use(Switch);
app.use(Spin);
app.use(Checkbox);
app.use(Breadcrumb);
app.use(GlobalCmComponent);
app.use(Carousel);
app.use(loader);

app.mount("#app");

app.config.globalProperties.$message = message;
