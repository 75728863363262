export default {
  namespaced: true,

  state: {
    filter: "",
    search: ""
  },

  getters: {
    filter: (state) => state.filter,
    search: (state) => state.search
  },

  mutations: {
    SET_FILTER: (state, filter) => {
      state.filter = filter;
    },
    SET_SEARCH: (state, search) => {
      state.search = search;
    },
    CLEAN_FILTERS: (state) => {
      state.filter = "";
      state.search = "";
    }
  },

  actions: {
    setFilter: ({ commit }, payload) => {
      commit("SET_FILTER", payload);
    },
    setSearch: ({ commit }, payload) => {
      commit("SET_SEARCH", payload);
    },
    cleanFilters: ({ commit }) => {
      commit("CLEAN_FILTERS");
    }
  }
};
