<template>
  <a-config-provider :locale="esEs">
    <component :is="this.$route.meta.layout || 'div'">
      <router-view />
    </component>
  </a-config-provider>
</template>

<script>
import esEs from "ant-design-vue/es/locale/es_ES";
// import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import moment from "moment";

moment.locale("es");

export default {
  name: "App",
  // components: {
  //   ChartJsPluginDataLabels
  // },
  setup() {
    return {
      esEs
    };
  }
};
</script>
