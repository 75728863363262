<template>
  <div
    class="layout-inicio flex flex-col bg-gradient-to-r from-green-900 to-gray-800 backdrop-filter backdrop-blur-lg w-full min-h-screen items-center justify-between py-20 px-6 h-full"
  >
    <header class="mb-12">
      <h1 class="text-5xl font-sans text-white">SIVINUR</h1>
    </header>

    <main class="mb-auto w-full mx-auto">
      <slot></slot>
    </main>

    <router-link to="/" class="font-bold text-gray-200" block>Ir a pantalla principal</router-link>

    <a-button type="ghost" class="border-none font-bold text-gray-200 my-1" @click="verTerminos"
      >Ver términos y condiciones</a-button
    >

    <footer class="pt-6">
      <p class="text-center font-semibold text-xs text-gray-400">SIVINUR v{{ version }}</p>
    </footer>

    <a-modal
      v-model:visible="isVisibleModalTerminos"
      title="TERMINOS Y CONDICIONES DE CONFIDENCIALIDAD"
      :footer="false"
      width="80%"
      @close="closeModal"
    >
      <PoliticaPrivacidadDetalle />
    </a-modal>
  </div>
</template>

<script>
import { ref } from "vue";
import { version } from "../../../package.json";
import PoliticaPrivacidadDetalle from "@/views/public/PoliticaPrivacidadDetalle.vue";

export default {
  components: {
    PoliticaPrivacidadDetalle
  },
  setup() {
    const isVisibleModalTerminos = ref(false);
    const verTerminos = () => {
      isVisibleModalTerminos.value = true;
    };

    const closeModal = () => {
      isVisibleModalTerminos.value = false;
    };

    return {
      version,
      verTerminos,
      isVisibleModalTerminos,
      closeModal
    };
  }
};
</script>

<style scoped lang="less"></style>
