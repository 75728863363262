export default {
  namespaced: true,

  state: {
    periodo_ini: "",
    periodo_fin: "",
    provincia: "",
    distrito: "",
    quintil: "",
  },

  getters: {
    periodo_ini: (state) => state.periodo_ini,
    periodo_fin: (state) => state.periodo_fin,
    provincia: (state) => state.provincia,
    distrito: (state) => state.distrito,
    quintil: (state) => state.quintil,
  },

  mutations: {
    SET_PERIODO_INI: (state, periodo_ini) => {
      state.periodo_ini = periodo_ini;
    },
    SET_PERIODO_FIN: (state, periodo_fin) => {
      state.periodo_fin = periodo_fin;
    },
    SET_PROVINCIA: (state, provincia) => {
      state.provincia = provincia;
    },
    SET_DISTRITO: (state, distrito) => {
      state.distrito = distrito;
    },
    SET_QUINTIL: (state, quintil) => {
      state.quintil = quintil;
    },
    CLEAN_FILTERS: (state) => {
      state.periodo_ini = "";
      state.periodo_fin = "";
      state.provincia = "";
      state.distrito = "";
      state.quintil = "";
    }
  },

  actions: {
    setDistrito: ({ commit }, payload) => {
      commit("SET_DISTRITO", payload);
    },
    setProvincia: ({ commit }, payload) => {
      commit("SET_PROVINCIA", payload);
    },
    setPeriodoIni: ({ commit }, payload) => {
      commit("SET_PERIODO_INI", payload);
    },
    setPeriodoFin: ({ commit }, payload) => {
      commit("SET_PERIODO_FIN", payload);
    },
    setQuintil: ({ commit }, payload) => {
      commit("SET_QUINTIL", payload);
    },
    cleanFilters: ({ commit }) => {
      commit("CLEAN_FILTERS");
    }
  }
};
