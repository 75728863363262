export default {
  namespaced: true,

  state: {
    periodo_ini: "",
    periodo_fin: "",
    ubigeo: "",
    periodo: "",
    provincia: "",
    distrito: "",
    sigla: "",
    quintil: "",
    anio: "",
    fecha_corte: ""
  },

  getters: {
    periodo_ini: (state) => state.periodo_ini,
    periodo_fin: (state) => state.periodo_fin,
    ubigeo: (state) => state.ubigeo,
    periodo: (state) => state.periodo,
    provincia: (state) => state.provincia,
    distrito: (state) => state.distrito,
    quintil: (state) => state.quintil,
    sigla: (state) => state.sigla,
    anio: (state) => state.anio,
    fecha_corte: (state) => state.fecha_corte
  },

  mutations: {
    SET_ANIO: (state, anio) => {
      state.anio = anio;
    },
    SET_PERIODO_INI: (state, periodo_ini) => {
      state.periodo_ini = periodo_ini;
    },
    SET_PERIODO_FIN: (state, periodo_fin) => {
      state.periodo_fin = periodo_fin;
    },
    SET_UBIGEO: (state, ubigeo) => {
      state.ubigeo = ubigeo;
    },
    SET_PERIODO: (state, periodo) => {
      state.periodo = periodo;
    },
    SET_PROVINCIA: (state, provincia) => {
      state.provincia = provincia;
    },
    SET_DISTRITO: (state, distrito) => {
      state.distrito = distrito;
    },
    SET_QUINTIL: (state, quintil) => {
      state.quintil = quintil;
    },
    SET_SIGLA: (state, sigla) => {
      state.sigla = sigla;
    },
    SET_FECHA_CORTE: (state, fecha_corte) => {
      state.fecha_corte = fecha_corte;
    },
    CLEAN_FILTERS: (state) => {
      state.periodo_ini = "";
      state.periodo_fin = "";
      state.ubigeo = "";
      state.periodo = "";
      state.provincia = "";
      state.distrito = "";
      state.quintil = "";
      state.sigla = "";
      state.anio = "";
      state.fecha_corte = "";
    }
  },

  actions: {
    setAnio: ({ commit }, payload) => {
      commit("SET_ANIO", payload);
    },
    setPeriodoIni: ({ commit }, payload) => {
      commit("SET_PERIODO_INI", payload);
    },
    setPeriodoFin: ({ commit }, payload) => {
      commit("SET_PERIODO_FIN", payload);
    },
    setUbigeo: ({ commit }, payload) => {
      commit("SET_UBIGEO", payload);
    },
    setDistrito: ({ commit }, payload) => {
      commit("SET_DISTRITO", payload);
    },
    setProvincia: ({ commit }, payload) => {
      commit("SET_PROVINCIA", payload);
    },
    setPeriodo: ({ commit }, payload) => {
      commit("SET_PERIODO", payload);
    },
    setSigla: ({ commit }, payload) => {
      commit("SET_SIGLA", payload);
    },
    setFechaCorte: ({ commit }, payload) => {
      commit("SET_FECHA_CORTE", payload);
    },
    setQuintil: ({ commit }, payload) => {
      commit("SET_QUINTIL", payload);
    },
    cleanFilters: ({ commit }) => {
      commit("CLEAN_FILTERS");
    }
  }
};
