<template>
  <header class="flex w-full justify-between py-2 px-8 items-center bg-gray-800 fixed z-10">
    <div class="flex gap-2 items-center">
      <router-link to="/menu">
        <div class="flex items-center gap-x-4">
          <h1 class="text-2xl md:text-3xl font-bold text-gray-200">SIVINUR</h1>
          <span class="hidden md:block h-8 w-1 bg-blue-500"></span>
          <span class="hidden md:block">
            <p class="font-semibold text-xs text-gray-400">
              SISTEMA DE INFORMACIÓN INTEGRADO <br />DE VIGILANCIA NUTRICIONAL REGIONAL
            </p>
          </span>
        </div>
      </router-link>
      <div class="hidden lg:block lg:ml-10 text-gray-100">
        <p><span class="font-bold"></span> {{ establecimiento }}</p>
      </div>
    </div>

    <div>
      <a-button shape="circle" class="mr-2" @click="descargarManual">
        <template #icon> <QuestionCircleOutlined /></template>
      </a-button>

      <a-dropdown>
        <template #overlay>
          <a-menu>
            <router-link to="/mi-cuenta">
              <a-menu-item key="1"> <UserOutlined /> Mi cuenta </a-menu-item>
            </router-link>
            <a-menu-item key="2" @click="logout"><LogoutOutlined /> Cerrar sesión </a-menu-item>
          </a-menu>
        </template>
        <a-button>
          <UserOutlined />
          {{ userName }}
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </div>
  </header>
</template>

<script>
import logoMinsa from "@/assets/logo-minsa.png";
import {
  UserOutlined,
  DownOutlined,
  LogoutOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons-vue";
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import publicIp from "public-ip";
import UsuarioApi from "@/api/user";

export default {
  components: {
    UserOutlined,
    DownOutlined,
    LogoutOutlined,
    QuestionCircleOutlined
  },
  setup() {
    const store = useStore();
    const urlManual = ref("");
    const usuario = store.state.user.usuario;

    const logout = async () => {
      const payload = {
        usuario: usuario.usuario,
        ip: await publicIp.v4()
      };

      store
        .dispatch("user/logout", payload)
        .then((res) => console.log(res))
        .catch((err) => console.log(err)); // for debug
    };

    const obtieneEnlaceManual = () => {
      UsuarioApi.getParametroxDenominacion("ENLACE_MANUAL_SIVINUR")
        .then((res) => {
          urlManual.value = res.data[0].valor;
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      obtieneEnlaceManual();
    });

    const establecimiento = computed(() => {
      const esDiresaORed = [
        "DIRECCIÓN REGIONAL DE SALUD DE TACNA",
        "RED DE SALUD DE TACNA",
        "SIN ESTABLECIMIENTO"
      ];
      const establecimiento = store.state.user?.usuario?.establecimiento;

      if (esDiresaORed.includes(establecimiento)) {
        return establecimiento;
      } else {
        return "EESS: " + establecimiento || "SIN ESTABLECIMIENTO";
      }
    });

    const descargarManual = () => {
      window.open(urlManual.value, "_blank");
    };

    return {
      logoMinsa,
      logout,
      establecimiento,
      userName: usuario?.nombre,
      userUppercase: computed(() => (usuario?.nombre + " " + usuario?.apellido).toUpperCase()),
      descargarManual
    };
  }
};
</script>

<style></style>
