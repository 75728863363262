export default {
  namespaced: true,

  state: {
    periodo_ini: "",
    periodo_fin: "",
    microred: "",
    establecimiento: ""
  },

  getters: {
    periodo_ini: (state) => state.periodo_ini,
    periodo_fin: (state) => state.periodo_fin,
    microred: (state) => state.microred,
    establecimiento: (state) => state.establecimiento
  },

  mutations: {
    SET_PERIODO_INI: (state, periodo_ini) => {
      state.periodo_ini = periodo_ini;
    },
    SET_PERIODO_FIN: (state, periodo_fin) => {
      state.periodo_fin = periodo_fin;
    },
    SET_MICRORED: (state, microred) => {
      state.microred = microred;
    },
    SET_ESTABLECIMIENTO: (state, establecimiento) => {
      state.establecimiento = establecimiento;
    }
  },

  actions: {
    setEstablecimiento: ({ commit }, payload) => {
      commit("SET_ESTABLECIMIENTO", payload);
    },
    setMicrored: ({ commit }, payload) => {
      commit("SET_MICRORED", payload);
    },
    setPeriodoIni: ({ commit }, payload) => {
      commit("SET_PERIODO_INI", payload);
    },
    setPeriodoFin: ({ commit }, payload) => {
      commit("SET_PERIODO_FIN", payload);
    }
  }
};
