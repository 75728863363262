<template>
  <div class="flex flex-col bg-gray-100 w-full mx-auto min-h-screen items-center justify-between">
    <LayoutSistemHeader />
    <main class="w-full px-6 py-4 mt-10 lg:mt-14">
      <slot></slot>
    </main>
    <footer class="p-7 font-semibold text-xs text-gray-400">SIVINUR v{{ version }}</footer>
  </div>
</template>

<script>
import LayoutSistemHeader from "./LayoutSistemHeader.vue";
import { version } from "../../../package.json";

export default {
  name: "LayoutSistem",
  data() {
    return {
      version
    };
  },
  components: {
    LayoutSistemHeader
  }
};
</script>

<style></style>
