import { generateNavByModules } from "@/utils";

const getters = {
  token: (state) => state.user.token,
  usuario: (state) => state.user.usuario,
  isAuthenticated: (state) => !!state.user.token,
  hasModulos: (state) => state.user.modulos.length,
  userRoles: (state) => state.user.usuario?.roles,
  navItems: (state) => generateNavByModules(state.user.modulos)
};
export default getters;
