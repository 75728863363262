export default {
  namespaced: true,

  state: {
    search: "",
    provincia: "",
    distrito: "",
    departamento: "TACNA"
  },

  getters: {
    search: (state) => state.search,
    departamento: (state) => state.departamento,
    provincia: (state) => state.provincia,
    distrito: (state) => state.distrito
  },

  mutations: {
    SET_DEPARTAMENTO: (state, filter) => {
      state.departamento = filter;
    },
    SET_PROVINCIA: (state, filter) => {
      state.provincia = filter;
    },
    SET_DISTRITO: (state, filter) => {
      state.distrito = filter;
    },
    SET_SEARCH: (state, search) => {
      state.search = search;
    },
    CLEAN_FILTERS: (state) => {
      state.filter = "all";
      state.search = "";
    }
  },

  actions: {
    setDepartamento: ({ commit }, payload) => {
      commit("SET_DEPARTAMENTO", payload);
    },
    setProvincia: ({ commit }, payload) => {
      commit("SET_PROVINCIA", payload);
    },
    setDistrito: ({ commit }, payload) => {
      commit("SET_DISTRITO", payload);
    },
    setSearch: ({ commit }, payload) => {
      commit("SET_SEARCH", payload);
    },
    cleanFilters: ({ commit }) => {
      commit("CLEAN_FILTERS");
    }
  }
};
