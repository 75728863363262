export default {
  namespaced: true,

  state: {
    search: "",
    establecimiento: "all",
    distrito: "all"
  },

  getters: {
    search: (state) => state.search,
    establecimiento: (state) => state.establecimiento,
    distrito: (state) => state.distrito
  },

  mutations: {
    SET_SEARCH: (state, search) => {
      state.search = search;
    },
    SET_ESTABLECIMIENTO: (state, establecimiento) => {
      state.establecimiento = establecimiento;
    },
    SET_DISTRITO: (state, distrito) => {
      state.distrito = distrito;
    },
    CLEAN_FILTERS: (state) => {
      state.search = "";
      state.establecimiento = "all";
      state.distrito = "all";
    }
  },

  actions: {
    setSearch: ({ commit }, payload) => {
      commit("SET_SEARCH", payload);
    },
    setEstablecimiento: ({ commit }, payload) => {
      commit("SET_ESTABLECIMIENTO", payload);
    },
    setDistrito: ({ commit }, payload) => {
      commit("SET_DISTRITO", payload);
    },
    cleanFilters: ({ commit }) => {
      commit("CLEAN_FILTERS");
    }
  }
};
