export default {
  namespaced: true,

  state: {
    filter: "",
    provincia: "",
    distrito: "",
    periodo: "",
    periodo_inicio: "",
    periodo_fin: "",
    search: "",
    sigla: "",
    establecimiento: ""
  },

  getters: {
    establecimiento: (state) => state.establecimiento,
    provincia: (state) => state.provincia,
    distrito: (state) => state.distrito,
    filter: (state) => state.filter,
    search: (state) => state.search,
    periodo: (state) => state.periodo,
    periodo_inicio: (state) => state.periodo_inicio,
    periodo_fin: (state) => state.periodo_fin,
    sigla: (state) => state.sigla
  },

  mutations: {
    SET_FILTER: (state, filter) => {
      state.filter = filter;
    },
    SET_PROVINCIA: (state, provincia) => {
      state.provincia = provincia;
    },
    SET_ESTABLECIMIENTO: (state, establecimiento) => {
      state.establecimiento = establecimiento;
    },
    SET_DISTRITO: (state, distrito) => {
      state.distrito = distrito;
    },
    SET_SEARCH: (state, search) => {
      state.search = search;
    },
    SET_PERIODO: (state, periodo) => {
      state.periodo = periodo;
    },
    SET_PERIODO_INICIO: (state, periodo_inicio) => {
      state.periodo_inicio = periodo_inicio;
    },
    SET_PERIODO_FIN: (state, periodo_fin) => {
      state.periodo_fin = periodo_fin;
    },
    SET_SIGLA: (state, sigla) => {
      state.sigla = sigla;
    },
    CLEAN_FILTERS: (state) => {
      state.filter = "";
      state.provincia = "";
      state.distrito = "";
      state.search = "";
      state.sigla = "";
      state.establecimiento = "";
    }
  },

  actions: {
    setProvincia: ({ commit }, payload) => {
      commit("SET_PROVINCIA", payload);
    },
    setDistrito: ({ commit }, payload) => {
      commit("SET_DISTRITO", payload);
    },
    setFilter: ({ commit }, payload) => {
      commit("SET_FILTER", payload);
    },
    setSearch: ({ commit }, payload) => {
      commit("SET_SEARCH", payload);
    },
    setSigla: ({ commit }, payload) => {
      commit("SET_SIGLA", payload);
    },
    setPeriodo: ({ commit }, payload) => {
      commit("SET_PERIODO", payload);
    },
    setPeriodoInicio: ({ commit }, payload) => {
      commit("SET_PERIODO_INICIO", payload);
    },
    setPeriodoFin: ({ commit }, payload) => {
      commit("SET_PERIODO_FIN", payload);
    },
    setEstablecimiento: ({ commit }, payload) => {
      commit("SET_ESTABLECIMIENTO", payload);
    },
    cleanFilters: ({ commit }) => {
      commit("CLEAN_FILTERS");
    }
  }
};
