export default {
  namespaced: true,

  state: {
    provincia: "",
    distrito: "",
    search: ""
  },

  getters: {
    provincia: (state) => state.provincia,
    distrito: (state) => state.distrito,
    search: (state) => state.search
  },

  mutations: {
    SET_PROVINCIA: (state, provincia) => {
      state.provincia = provincia;
    },
    SET_DISTRITO: (state, distrito) => {
      state.distrito = distrito;
    },
    SET_SEARCH: (state, search) => {
      state.search = search;
    },
    CLEAN_FILTERS: (state) => {
      state.provincia = "";
      state.distrito = "";
      state.search = "";
    }
  },

  actions: {
    setprovincia: ({ commit }, payload) => {
      commit("SET_PROVINCIA", payload);
    },
    setdistrito: ({ commit }, payload) => {
      commit("SET_DISTRITO", payload);
    },
    setsearch: ({ commit }, payload) => {
      commit("SET_SEARCH", payload);
    },
    cleanFilters: ({ commit }) => {
      commit("CLEAN_FILTERS");
    }
  }
};
