import LayoutSistem from "@/layout/LayoutSistem";
import LayoutPublic from "@/layout/LayoutPublic";
import LayoutAuth from "@/layout/LayoutAuth";

export default [
  // -------------- Rutas Iniciales --------------
  {
    path: "/",
    name: "welcome",
    component: () => import("@/views/public/Welcome.vue"),
    meta: {
      layout: LayoutPublic,
      isPublic: true
    }
  },
  {
    path: "/ind",
    name: "public-indicador-list",
    component: () => import("@/views/public/IndicadorList.vue"),
    meta: {
      layout: LayoutPublic,
      isPublic: true
    }
  },
  {
    path: "/ind/:indicador",
    name: "public-indicador",
    component: () => import("@/views/public/Indicador.vue"),
    meta: {
      layout: LayoutPublic,
      isPublic: true
    }
  },
  {
    path: "/menu",
    name: "mainmenu",
    component: () => import("@/views/menus/MainMenu.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/menu"]
    }
  },
  // -------------- Modulo de Auth --------------
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      layout: LayoutAuth,
      isPublic: true
    }
  },
  {
    path: "/forgot-password",
    name: "forgotpassword",
    component: () => import("@/views/auth/ForgotPassword.vue"),
    meta: {
      layout: LayoutAuth,
      isPublic: true
    }
  },
  {
    path: "/recover-password",
    name: "recoverpassword",
    component: () => import("@/views/auth/RecoverPassword.vue"),
    meta: {
      layout: LayoutAuth,
      isPublic: true
    }
  },
  {
    path: "/politica-privacidad",
    name: "politica-privacidad",
    component: () => import("@/views/public/PoliticaPrivacidad.vue"),
    meta: {
      layout: LayoutPublic,
      isPublic: true
    }
  },
  {
    path: "/mi-cuenta",
    name: "micuenta",
    component: () => import("@/views/auth/MiCuenta.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/micuenta"]
    }
  },
  // -------------- Modulo de nutricional --------------
  {
    path: "/nutricional/gestantes",
    name: "nutricional-gestantes",
    component: () => import("@/views/nutricional/Gestantes.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/nutricional/gestantes"]
    }
  },
  {
    path: "/nutricional/ninios",
    name: "nutricional-ninios",
    component: () => import("@/views/nutricional/Ninios.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/nutricional/ninios"]
    }
  },
  {
    path: "/nutricional/ninias",
    name: "nutricional-ninias",
    component: () => import("@/views/nutricional/Ninias.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/nutricional/ninias"]
    }
  },
  {
    path: "/nutricional/ninios-anemia",
    name: "nutricional-ninios-anemia",
    component: () => import("@/views/nutricional/AnemiaNinios.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/nutricional/ninios-anemia"]
    }
  },
  {
    path: "/nutricional/tabular",
    name: "nutricional-tabular",
    component: () => import("@/views/nutricional/NutricionalTabular.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/nutricional/tabular"]
    }
  },
  {
    path: "/nutricional/tabular-gestante",
    name: "nutricional/tabular-gestante",
    component: () => import("@/views/nutricional/NutricionalGestanteTabular.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/nutricional/tabular-gestante"]
    }
  },
  {
    path: "/nutricional/graficas",
    name: "nutricional-grafica",
    component: () => import("@/views/nutricional/NutricionalGraficas.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/nutricional/graficas"]
    }
  },
  {
    path: "/nutricional/graficas-gestante",
    name: "nutricional-graficas-gestante",
    component: () => import("@/views/nutricional/NutricionalGestanteGraficas.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/nutricional/graficas-gestante"]
    }
  },
  {
    path: "/nutricional/tabular-gestante",
    name: "nutricional-tabular-gestante",
    component: () => import("@/views/nutricional/NutricionalGestanteTabular.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/nutricional/tabular-gestante"]
    }
  },
  {
    path: "/graficas/:tipo",
    name: "graficas",
    component: () => import("@/views/nutricional/Graficas.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/graficas/desnutricion", "/graficas/anemia"]
    }
  },
  // -------------- Modulo de configuración --------------
  {
    path: "/actividad",
    name: "actividad",
    component: () => import("@/views/configuracion/Actividad.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false
      // availableRoute: []
    }
  },
  {
    path: "/personal",
    name: "personal",
    component: () => import("@/views/configuracion/Personal.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false
      // availableRoute: []
    }
  },
  {
    path: "/ubigeo",
    name: "ubigeo",
    component: () => import("@/views/configuracion/Ubigeo.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false
      // availableRoute: []
    }
  },
  {
    path: "/parametros",
    name: "parametros",
    component: () => import("@/views/configuracion/Parametros.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/parametros"]
    }
  },
  {
    path: "/perfiles",
    name: "perfiles",
    component: () => import("@/views/configuracion/Perfiles.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/perfiles"]
    }
  },
  {
    path: "/usuarios",
    name: "usuarios",
    component: () => import("@/views/configuracion/Usuarios.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/usuarios"]
    }
  },
  // -------------- Modulo de maestros --------------
  {
    path: "/clasificacion-enfermedad",
    name: "clasificacionenfermedad",
    component: () => import("@/views/maestros/ClasificacionEnfermedad.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false
      // availableRoute: []
    }
  },
  {
    path: "/periodo",
    name: "periodo",
    component: () => import("@/views/maestros/Periodo.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false
      // availableRoute: []
    }
  },
  {
    path: "/unidad-productora-servicio",
    name: "unidad-productora-servicio",
    component: () => import("@/views/maestros/ProductoraServicio.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false
      // availableRoute: []
    }
  },
  {
    path: "/tipo-documento",
    name: "tipodocumento",
    component: () => import("@/views/maestros/TipoDocumento.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false
      // availableRoute: []
    }
  },
  {
    path: "/tipo-seguro",
    name: "tiposeguro",
    component: () => import("@/views/maestros/TipoSeguro.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false
      // availableRoute: []
    }
  },

  // -------------- Modulo de proyecto --------------
  {
    path: "/indicador",
    name: "indicador",
    component: () => import("@/views/proyecto/Indicador.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/indicador"]
    }
  },
  {
    path: "/indicador/:indicador",
    name: "indicador-resumen",
    component: () => import("@/views/proyecto/IndicadorResumen.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/indicador"]
    }
  },
  {
    path: "/indicador/:indicador/detalles",
    name: "indicadores-detalles",
    component: () => import("@/views/proyecto/IndicadorDetalle.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/indicador"]
    }
  },
  {
    path: "/indicador/:indicador/parametros",
    name: "indicador-parametros",
    component: () => import("@/views/proyecto/IndicadorParametros.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/indicador"]
    }
  },
  {
    path: "/indicador/:indicador/graficas",
    name: "indicador-graficas",
    component: () => import("@/views/proyecto/IndicadorGraficas.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/indicador"]
    }
  },
  {
    path: "/indicador/:indicador/establecimiento",
    name: "indicador-graficas-establecimiento",
    component: () => import("@/views/proyecto/IndicadorGraficasEstablecimiento.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/indicador"]
    }
  },
  {
    path: "/indicador/:indicador/establecimiento/:idestablecimiento",
    name: "indicador-graficas-establecimiento",
    component: () => import("@/views/proyecto/IndicadorGraficasEstablecimiento.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/indicador"]
    }
  },
  {
    path: "/indicador-establecimiento",
    name: "indicador-establecimiento",
    component: () => import("@/views/proyecto/IndicadorParaEstablecimiento.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/indicador-establecimiento"]
    }
  },
  {
    path: "/indicador-establecimiento/:indicador",
    name: "indicador-establecimiento-resumen",
    component: () => import("@/views/proyecto/IndicadorParaEstablecimientoResumen.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/indicador-establecimiento"]
    }
  },
  {
    path: "/indicador-establecimiento/:indicador/detalles",
    name: "indicador-establecimiento-detalles",
    component: () => import("@/views/proyecto/IndicadorParaEstablecimientoDetalle.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/indicador-establecimiento"]
    }
  },
  {
    path: "/padron",
    name: "padron",
    component: () => import("@/views/proyecto/PadronNominal.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/padron"]
    }
  },
  {
    path: "/procesar-masivamente",
    name: "procesar-masivamente",
    component: () => import("@/views/proyecto/IndicadorPeriodoEvaluacionMasivo.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/procesar-masivamente"]
    }
  },
  // -------------- Modulo de estadísticas --------------
  {
    path: "/cantidad-porcentual",
    name: "cantidad-porcentual",
    component: () => import("@/views/estadisticas/CantidadPorcentual.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/cantidad-porcentual"]
    }
  },
  {
    path: "/padron-observados",
    name: "padron-observados",
    component: () => import("@/views/proyecto/PadronNominalObservados.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/padron-observados"]
    }
  },
  {
    path: "/grupos",
    name: "grupo",
    component: () => import("@/views/vigilancia/Grupos.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/grupos"]
    }
  },
  {
    path: "/cantidad-porcentual/:tipopaciente",
    name: "cantidad-porcentual-paciente",
    component: () => import("@/views/estadisticas/CantidadPorcentualPaciente.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/cantidad-porcentual/ninios", "/cantidad-porcentual/gestantes"]
    }
  },
  {
    path: "/cantidad-porcentual/:tipopaciente-public",
    name: "cantidad-porcentual-paciente-public",
    component: () => import("@/views/public/CantidadPorcentualPaciente.vue"),
    meta: {
      layout: LayoutPublic,
      isPublic: true,
      availableRoute: [
        "/cantidad-porcentual/ninios-public",
        "/cantidad-porcentual/gestantes-public"
      ]
    }
  },
  {
    path: "/mapa-gestante",
    name: "/mapa-gestante",
    component: () => import("@/views/estadisticas/MapaGestantes.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/mapa-gestante"]
    }
  },
  {
    path: "/mapa-gestante-public",
    name: "/mapa-gestante-public",
    component: () => import("@/views/public/MapaGestantes.vue"),
    meta: {
      layout: LayoutPublic,
      isPublic: true,
      availableRoute: ["/mapa-gestante-public"]
    }
  },
  {
    path: "/mapa",
    name: "mapa",
    component: () => import("@/views/estadisticas/Mapa.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/mapa"]
    }
  },
  {
    path: "/mapa-public",
    name: "mapa-public",
    component: () => import("@/views/public/Mapa.vue"),
    meta: {
      layout: LayoutPublic,
      isPublic: true,
      availableRoute: ["/mapa"]
    }
  },
  {
    path: "/grafico-paciente-anemia",
    name: "grafico-paciente-anemia",
    component: () => import("@/views/estadisticas/GraficoPacienteAnemia.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/grafico-paciente-anemia"]
    }
  },
  {
    path: "/paquete-preventivo-gestante",
    name: "paquete-preventivo-gestante",
    component: () => import("@/views/estadisticas/PaquetePreventivoGestante.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/paquete-preventivo-gestante"]
    }
  },
  {
    path: "/paquete-preventivo-ninio",
    name: "paquete-preventivo-ninio",
    component: () => import("@/views/estadisticas/PaquetePreventivoNinio.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/paquete-preventivo-ninio"]
    }
  },
  // -------------- Modulo de importar - exportar --------------
  {
    path: "/carga-base-datos",
    name: "carga-base-datos",
    component: () => import("@/views/importar-exportar/CargaBaseDatos.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/carga-base-datos"]
    }
  },
  {
    path: "/carga-complementaria",
    name: "carga-complementaria",
    component: () => import("@/views/importar-exportar/CargaComplementaria.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/carga-complementaria"]
    }
  },
  {
    path: "/exportar",
    name: "exportar",
    component: () => import("@/views/importar-exportar/Exportar.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/exportar"]
    }
  },
  {
    path: "/exportar-sivinur",
    name: "exportar-sivinur",
    component: () => import("@/views/importar-exportar/ExportarSivinur.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/exportar-sivinur"]
    }
  },
  {
    path: "/importar",
    name: "importar",
    component: () => import("@/views/importar-exportar/Importar.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/importar"]
    }
  },

  // -------------- Modulo de registro --------------
  {
    path: "/establecimientos",
    name: "establecimientos",
    component: () => import("@/views/registro/Establecimientos.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/establecimientos"]
    }
  },
  {
    path: "/pacientes/:tipopaciente",
    name: "registros-pacientes",
    component: () => import("@/views/registro/Pacientes.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/pacientes/ninios", "/pacientes/gestantes"]
    }
  },
  // -------------- Modulo de vigilancia --------------
  {
    path: "/mensajes",
    name: "mensajes",
    component: () => import("@/views/vigilancia/Mensajes.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/mensajes"]
    }
  },
  {
    path: "/programacion-envios",
    name: "programacion-envios",
    component: () => import("@/views/vigilancia/ProgramacionEnvios.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/programacion-envios"]
    }
  },
  {
    path: "/destinatarios",
    name: "destinatarios",
    component: () => import("@/views/vigilancia/Destinatarios.vue"),
    meta: {
      layout: LayoutSistem,
      isPublic: false,
      availableRoute: ["/destinatarios"]
    }
  },
  // Error 404 debe ser el último
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/auth/Error404.vue"),
    meta: {
      isPublic: true
    }
  }
];
