import Cookies from "js-cookie";

const TOKEN_KEY = "sivinur_token";
const USUARIO = "sivinur_usuario";
// const MODULOS = "sivinur_modulos";
const expires = process.env.VUE_APP_EXP_COOKIE || 1; // expresed in days

export const getToken = () => {
  return Cookies.get(TOKEN_KEY);
};

export const setToken = (token) => {
  return Cookies.set(TOKEN_KEY, token, { expires: parseInt(expires) });
};

export const removeToken = () => {
  return Cookies.remove(TOKEN_KEY);
};

export const setUsuario = (usuario) => {
  return localStorage.setItem(USUARIO, JSON.stringify(usuario));
};

export const getUsuario = () => {
  return (Cookies.get(TOKEN_KEY) && JSON.parse(localStorage.getItem(USUARIO))) || null;
};

export const removeUsuario = () => {
  return localStorage.removeItem(USUARIO);
};

// export const setModulos = (modulos) => {
//   return localStorage.setItem(MODULOS, JSON.stringify(modulos));
// };

// export const getModulos = () => {
//   return JSON.parse(localStorage.getItem(MODULOS)) || [];
// };

// export const removeModulos = () => {
//   return localStorage.removeItem(MODULOS);
// };

export const removeAllTokens = () => {
  removeToken();
  // removeModulos();
  removeUsuario();
  return "tokens deleted successfully";
};
