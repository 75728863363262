export default {
  namespaced: true,

  state: {
    filter: "all",
    search: "",
    isProcessing: false
  },

  getters: {
    filter: (state) => state.filter,
    search: (state) => state.search
  },

  mutations: {
    SET_FILTER: (state, filter) => {
      state.filter = filter;
    },
    SET_SEARCH: (state, search) => {
      state.search = search;
    },
    CLEAN_FILTERS: (state) => {
      state.filter = "all";
      state.search = "";
    },
    SET_PROCESSING: (state, isProcesing) => {
      state.isProcessing = isProcesing;
    }
  },

  actions: {
    setFilter: ({ commit }, payload) => {
      commit("SET_FILTER", payload);
    },
    setSearch: ({ commit }, payload) => {
      commit("SET_SEARCH", payload);
    },
    cleanFilters: ({ commit }) => {
      commit("CLEAN_FILTERS");
    },
    setProcesing: ({ commit }, payload) => {
      commit("SET_PROCESSING", payload);
    }
  }
};
