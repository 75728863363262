import store from "@/store";
import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { removeAllTokens } from "@/utils/auth";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            offset: { x: 0, y: 0 },
            behavior: "smooth"
          });
        }, 2000);
      });
    }

    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  const isAuthenticated = store.getters["isAuthenticated"]; // if has jwt token

  if (isAuthenticated) {
    try {
      await store.dispatch("user/getModulos");
    } catch (error) {
      removeAllTokens();
      next({ name: "login" });
    }
  }

  const routeIsPublic = to.meta.isPublic || false;
  const availableRoute = to.meta.availableRoute || [];

  if (!isAuthenticated && routeIsPublic) {
    next();
    NProgress.done();
    window.scrollTo({ top: 0, behavior: "smooth" });
    return;
  }

  if (!isAuthenticated) {
    next({ name: "login" });
    NProgress.done();
    return;
  }

  const opcionRoutes = store.state.user.modulos.map((m) => m.ruta);
  const hasAccessForRol = availableRoute.some((r) => opcionRoutes.indexOf(r) >= 0);

  if (hasAccessForRol) {
    next();
    NProgress.done();
    window.scrollTo({ top: 0, behavior: "smooth" });
    return;
  }

  if (!hasAccessForRol) {
    next({ name: "mainmenu" });
    NProgress.done();
    return;
  }

  next({ name: "login" });
  NProgress.done();

  return;
});

export default router;
