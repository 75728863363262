export default {
  namespaced: true,

  state: {
    provincia: "",
    centroPoblado: ""
  },

  getters: {
    provincia: (state) => state.provincia,
    centroPoblado: (state) => state.centroPoblado
  },

  mutations: {
    SET_PROVINCIA: (state, provincia) => {
      state.provincia = provincia;
    },
    SET_CENTRO_POBLADO: (state, centroPoblado) => {
      state.centroPoblado = centroPoblado;
    },
    CLEAN_FILTERS: (state) => {
      (state.provincia = ""), (state.centroPoblado = "");
    }
  },

  actions: {
    setprovincia: ({ commit }, payload) => {
      commit("SET_PROVINCIA", payload);
    },
    setcentropoblado: ({ commit }, payload) => {
      commit("SET_CENTRO_POBLADO", payload);
    },
    cleanFilters: ({ commit }) => {
      commit("CLEAN_FILTERS");
    }
  }
};
