/**
 * createRoutesByModules
 * @param {Array} entrada 
 * @returns Array
 * creado el 04 Oct 2021
 * 
 * referencias:
 * https://stackoverflow.com/questions/45064107/how-do-i-group-duplicate-objects-in-an-array
 * 
 * Varialbes de entrada:
 * ====================
 *  {
    idmodulo: '3',
    modulo: 'Configuración',
    icono: 'fas fa-cog',
    ordenmodulo: '3',
    nombre: 'PERFILES',
    ruta: '/perfiles',
    ordenopcion: '3'
  },
  {
    idmodulo: '3',
    modulo: 'Configuración',
    icono: 'fas fa-cog',
    ordenmodulo: '3',
    nombre: 'USUARIOS',
    ruta: '/usuarios',
    ordenopcion: '2'
  },
  {
    idmodulo: '4',
    modulo: 'Importar / Exportar',
    icono: 'fas fa-boxes',
    ordenmodulo: '4',
    nombre: 'IMPORTAR',
    ruta: '/importar',
    ordenopcion: '1'
  },

  Array de salida
  ================
  [
    {
    modulo: 'Configuración',
    idmodulo: '1',
    icono: 'fas fa-cog',
    children: [
      {path: '/parametros', name: 'A Parámetros'},
      {path: '/perfiles', name: 'A Perfiles'},
      {path: '/usuarios', name: 'A Usuarios'}
    ]
  },
  {
    modulo: 'Importar - Exportar',
    idmodulo: '2',
    icono: 'fas fa-boxes',
    children: [
      {path: '/exportar', name: 'B Exportar'},
      {path: '/importar', name: 'B Importar'}
    ]
  }
]
 */
export const generateNavByModules = (entrada = []) => {
  if (!entrada.length) return [];

  return entrada.reduce((a, b) => {
    var i = a.findIndex((x) => x.idmodulo === b.idmodulo);
    return (
      i === -1
        ? a.push({
            modulo: b.modulo,
            icono: b.icono,
            idmodulo: b.idmodulo,
            children: [
              {
                path: b.ruta,
                name: b.opcion,
                idopcion: b.idopcion
              }
            ]
          })
        : (a[i].children = [
            ...a[i].children,
            { path: b.ruta, name: b.opcion, idopcion: b.idopcion }
          ]),
      a
    );
  }, []);
};

/**
 * generateModules
 * ===============
 * @param {Array} entrada
 * @returns {Array}
 */
export const generateModules = (entrada = []) => {
  if (!entrada.length) return [];

  return entrada.reduce((a, b) => {
    a.includes(b.icono) ? a : a.push(b.icono);

    return a;
  }, []);
};

/**
 * groupPerfiles
 * ENTRADA: 
[
  {
      "idrol": "1",
      "nombre_rol": "ADMINISTRADOR RED",
      "descripcion_rol": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      "activo": "1",
      "nombre_opcion": "Perfiles",
      "id_opcion": "3",
      "idmodulo": "3",
      "nombre_modulo": "Configuración",
      "ruta": "/perfiles",
      "orden_modulo": "4",
      "orden_opcion": "3"
  },
  {
      "idrol": "1",
      "nombre_rol": "ADMINISTRADOR RED",
      "descripcion_rol": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      "activo": "1",
      "nombre_opcion": "Usuarios",
      "id_opcion": "2",
      "idmodulo": "3",
      "nombre_modulo": "Configuración",
      "ruta": "/usuarios",
      "orden_modulo": "4",
      "orden_opcion": "2"
  },
  {
      "idrol": "5",
      "nombre_rol": "RED TACNA",
      "descripcion_rol": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      "activo": "1",
      "nombre_opcion": "Importar",
      "id_opcion": "5",
      "idmodulo": "4",
      "nombre_modulo": "Import. Export.",
      "ruta": "/importar",
      "orden_modulo": "5",
      "orden_opcion": "1"
  },
]

SALIDA:
{
    "idrol": "1",
    "denominacion": "ADMINISTRADOR",
    "descripcion": "Acceso a todos los modulos del sistema",
    "activo": "1",
    "accesos": [
        {
            "idopcion": "1",
            "modulo": "Registro",
            "idmodulo": "5",
            "denominacion": "Establecimientos",
            "idrolopcion": "1"
        }, // y otros accesos
    ],
    "modulos": [
        "Registro",
        "Estadística",
        "Vigilancia",
        "Proyecto",
        "Import. Export.",
        "Nutricional",
        "Configuración"
    ]
}
 */

export const groupPerfiles = (entrada = []) => {
  return new Promise((resolve, reject) => {
    if (!entrada.length) reject([]);

    resolve(
      entrada.reduce((a, b) => {
        var i = a.findIndex((x) => x.idrol === b.idrol);

        if (i === -1) {
          a.push({
            idrol: b.idrol, // importante poner el id del rol
            denominacion: b.nombre_rol,
            descripcion: b.descripcion_rol,
            activo: b.activo,
            accesos: [
              {
                idopcion: b.id_opcion,
                modulo: b.nombre_modulo,
                idmodulo: b.idmodulo,
                denominacion: b.nombre_opcion,
                idrolopcion: b.idrolopcion
              }
            ],
            modulos: [b.nombre_modulo]
          });
        } else {
          a[i].accesos = [
            ...a[i].accesos,
            {
              idopcion: b.id_opcion,
              modulo: b.nombre_modulo,
              idmodulo: b.idmodulo,
              denominacion: b.nombre_opcion,
              idrolopcion: b.idrolopcion
            }
          ];

          a[i].modulos = a[i].modulos.includes(b.nombre_modulo)
            ? (a[i].modulos = [...a[i].modulos])
            : [...a[i].modulos, b.nombre_modulo];
        }

        return a;
      }, [])
    );
  });
};

/**
 * groupAccesos
 * Se usa en agregar y editar perfil
 * @param {Array} entrada
 * @returns {Array}
 */
export const groupAccesos = (entrada = []) => {
  return new Promise((resolve, reject) => {
    if (!entrada) reject([]);
    if (!entrada.length) resolve([]);

    resolve(
      entrada.reduce((a, b) => {
        var i = a.findIndex((x) => x.idmodulo === b.idmodulo);

        if (i === -1) {
          a.push({
            idmodulo: b.idmodulo, // importante poner el id para que lo agrupe
            title: b.modulo,
            key: "modulo-" + b.idmodulo,
            checkable: false,
            children: [
              {
                title: b.denominacion,
                key: b.idopcion
              }
            ]
          });
        } else {
          a[i].children = [...a[i].children, { title: b.denominacion, key: b.idopcion }];
        }

        return a;
      }, [])
    );
  });
};

/**
 * Group by ficha tecnica
 * @param {Array} entrada 
 * @returns {Array} agrupados por ficha tecnica
 * 
 * Entrada:
 *  [{
    "idindicador": "2",
    "denominacion": "Porcentaje de niñas/niños menores de 18 meses, sin diagnóstico de anemia entre los 6 y 11 meses, que reciben un paquete integrado de servicios preventivos: CRED, vacunas, dosaje de hemoglobina para descarte de anemia y suplementación con hierro.",
    "tituloreporte": "Porcentaje de niñas/niños menores de 18 meses, sin diagnóstico de anemia entre los 6 y 11 meses, que reciben un paquete integrado de servicios preventivos: CRED, vacunas, dosaje de hemoglobina para descarte de anemia y suplementación con hierro.",
    "titulonumerado": "Total de niñas/niños del denominador que recibieron paquete integrado de servicios preventivos (registrados en el HIS), de acuerdo al esquema vigente: CRED, vacunas, dosaje de hemoglobina para el descarte de anemia y suplementación con hierro.",
    "titulodenominado": "Total de niñas/niños que cumplen 544 días (17 meses 29 días) en el periodo de evaluación , con SIS, sin dato de seguro y sin seguro (Población MINSA y Gobierno Regional), registrados en el padrón nominal con DNI, según el ámbito de la jurisdicción determinado por la residencia registrada en el padron nominal. Sin diagnóstico de anemia registrado en el HIS entre los 170 y 364 días de edad (11 meses 29 días)",
    "abreviatura": "FG-02.01",
    "activo": "1",
    "idtipofichatecnica": "1",
    "idquery": "43",
    "query": "web_dawh_indicador2",
    "descripcion": "SELECT SUM(denominador) as total_denominador_establecimiento, SUM(numerador) as total_numerador_establecimiento,  establecimiento, (SELECT SUM(denominador)FROM sivi_data.ind_registro_fcg3 WHERE distrito LIKE @DISTRITO ) as total_denominador_distrito, (SELECT SUM(numerador)FROM sivi_data.ind_registro_fcg3 WHERE distrito LIKE @DISTRITO ) as total_numerador_distrito FROM sivi_data.ind_registro_fcg3 WHERE distrito LIKE @DISTRITO and idperiodo LIKE @IDPERIODO GROUP BY establecimiento"
  }]

Salida:
[
  {
    idtipofichatecnica: 1,
    indicadores: [
      {
        idindicador: 1,
        nombre: "Porcentaje de niñas/niños menores de 18 meses, sin diagnóstico de anemia entre los 6 y 11 meses, que reciben un paquete integrado de servicios preventivos: CRED, vacunas, dosaje de hemoglobina para descarte de anemia y suplementación con hierro."
      },
      {
        idindicador: 2,
        nombre: "Porcentaje de niñas/niños menores de 18 meses, sin diagnóstico de anemia entre los 6 y 11 meses, que reciben un paquete integrado de servicios preventivos: CRED, vacunas, dosaje de hemoglobina para descarte de anemia y suplementación con hierro."
      }
    ]
  },
  {
    idtipofichatecnica: 2,
    indicadores: [
      {
        idindicador: 1,
        nombre: "Porcentaje de niñas/niños menores de 18 meses, sin diagnóstico de anemia entre los 6 y 11 meses, que reciben un paquete integrado de servicios preventivos: CRED, vacunas, dosaje de hemoglobina para descarte de anemia y suplementación con hierro."
      },
      {
        idindicador: 2,
        nombre: "Porcentaje de niñas/niños menores de 18 meses, sin diagnóstico de anemia entre los 6 y 11 meses, que reciben un paquete integrado de servicios preventivos: CRED, vacunas, dosaje de hemoglobina para descarte de anemia y suplementación con hierro."
      }
    ]
  },
]
 */

// const COLORES_PAQUETES = {
//   1: "#A863A5",
//   2: "#ABCA29",
//   3: "#F39200",
//   4: "#1C84C2"
// };
const COLORES_PAQUETES = {
  1: "#A863A5",
  2: "#1C84C2",
  3: "#F39200",
  4: "#ABCA29"
};

const COLORES_FLECHA = {
  1: "morado-arrow-top",
  2: "azul-arrow-top",
  3: "naranja-arrow-top",
  4: "verde-arrow-top"
};

export const groupByPaquete = (entrada = []) => {
  return new Promise((resolve, reject) => {
    if (!entrada.length) reject([]);

    entrada = entrada.filter((indicador) => indicador.activo === "1");

    resolve(
      entrada.reduce((a, b) => {
        var i = a.findIndex((x) => x.idgrupo === b.idgrupo);

        if (i === -1) {
          a.push({
            idgrupo: b.idgrupo,
            bgcolor: COLORES_PAQUETES[b.idgrupo] || "#A863A5",
            nombre_grupo: b.nombre_grupo,
            colorFlecha: COLORES_FLECHA[b.idgrupo] || "morado-arrow-top",
            indicadores: [
              {
                idindicador: b.idindicador,
                tituloreporte: b.tituloreporte,
                abreviatura: b.abreviatura,
                total: b.total
              }
            ]
          });
        } else {
          a[i].indicadores = [
            ...a[i].indicadores,
            {
              idindicador: b.idindicador,
              tituloreporte: b.tituloreporte,
              abreviatura: b.abreviatura,
              adicional: b.adicional,
              total: b.total
            }
          ];
        }
        return a.sort((a, b) => a.idgrupo - b.idgrupo);
      }, [])
    );
  });
};

/**
 * shortName
 * ---------
 * Saca la primera palabra del nombre y apellido
 */

export const shortName = (nombres = "", apellidos = "") => {
  if (!nombres || !apellidos) return "";

  return nombres.split(" ")[0] + " " + apellidos.split(" ")[0];
};

/**
 * Validate Number
 * @param {String} myString
 * @returns {Boolean}
 * https://stackoverflow.com/a/28813213/9868383
 */
export const hasNumber = (myString) => {
  return /\d/.test(myString);
};

/**
 * Validate Number
 * @param {String} myString
 * @returns {Boolean}
 * https://stackoverflow.com/a/28813213/9868383
 */
export const hasMayus = (myString) => {
  return /[A-Z]/.test(myString);
};

/**
 * genTwoPointsLines
 * @param {Array} entrada 
 * @returns 
 * data:
  [
    {
      name: "Tacna",
      data: { 2010: 3, 2011: 4, 2012: 5, 2013: 3, 2014: 6 }
    },
    {
      name: "Nacional",
      data: { 2010: 5, 2011: 3, 2012: 4, 2013: 2, 2014: 6 }
    }
  ]
 */

export const genTwoPointsLines = (entrada = []) => {
  return new Promise((resolve, reject) => {
    if (!entrada) reject([]);
    if (!entrada.length) resolve([]);

    entrada = entrada.filter((item) => item.activo !== "0");

    const payload = [
      {
        name: "Tacna",
        data: {}
      },
      {
        name: "Nacional",
        data: {}
      }
    ];

    entrada.map((item) => {
      payload[0].data = { ...payload[0].data, [item.anio]: Number(item.valorlocal) };
      payload[1].data = { ...payload[1].data, [item.anio]: Number(item.valornacional) };
    });

    resolve(payload);
  });
};

/**
 * generaGraficosLine
 * @param {Array} entrada
 */
export const generaGraficosLine = (entrada = []) => {
  entrada = entrada.filter((item) => item.activo !== "0");

  return {
    valoresEjeX: entrada.map((p) => p.anio),
    nacional: entrada.map((p) => p.valornacional),
    local: entrada.map((p) => p.valorlocal)
  };
};

/**
 * filtraUsuarioAdmin
 * @param {Array} entrada
 * @returns {Array}
 * Fuente:
 * Filter multiple objects from array
 * https://stackoverflow.com/a/41067325/9868383
 */
export const filtraUsuarioAdmin = (entrada = []) => {
  // const usuariosAQuitar = ["12345678", usuarioActual];
  const usuariosAQuitar = ["12345678", "87654321"];

  return new Promise((resolve, reject) => {
    if (!entrada) reject([]);
    if (!entrada.length) resolve([]);

    resolve(entrada.filter((item) => !usuariosAQuitar.includes(item.usuario)));
  });
};

/**
 * filtraPerfilSuperadmin
 * @param {Array} entrada
 * @returns {Array}
 * Filter multiple objects from array
 * https://stackoverflow.com/a/41067325/9868383
 */
export const filtraPerfilSuperAdmin = (entrada = []) => {
  const perfilAQuitar = ["SUPERADMIN"];

  return new Promise((resolve, reject) => {
    if (!entrada) reject([]);
    if (!entrada.length) resolve([]);

    resolve(entrada.filter((item) => !perfilAQuitar.includes(item.denominacion)));
  });
};

/**
 * generateArrayAddDelete
 * ======================
 * Compara los dos arrays y devuelve lo que
 * se tiene que eliminar y agregar, esto sirve
 * para editar rolopciones y similares
 * @param {Array} arrayInitial el array que tenía
 * @param {Array} arrayFinal los nuevos ids que se deben guardar
 * @returns {Object} arrayAdd y arrayDelete
 *
 * Fuentes:
 * How to get the difference between two arrays in JavaScript?
 * https://stackoverflow.com/a/33034768/9868383
 */
export const generateArrayAddDelete = (initialArray = [], finalArray = []) => {
  return {
    arrayAdd: finalArray.filter((x) => !initialArray.includes(x)),
    arrayDelete: initialArray.filter((x) => !finalArray.includes(x))
  };
};

/**
 * atachMultiple
 * ===============
 * Crea el formato para agregar opciones o roles
 * según lo que pide la api, se usa para perfil y usuarios
 * @param {Array} array
 * @param {String} id id del rol
 * @returns {String} idopcion,idrol ejm: 14,39;15,39
 */
export const atachMultiple = (array = [], id = "") => {
  if (!array.length) return [];
  return array.map((i) => i + "," + id).join(";");
};

/**
 * How to print a number with commas as thousands separators in JavaScript
 * https://stackoverflow.com/a/2901298/9868383
 * @param {Number} x
 * @returns {String}
 */
export function numberWithCommas(number = 0) {
  if (!number) return 0;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * calculaPorcentaje
 * ================
 * @param {Number} paramNumerador
 * @param {Number} paramDenominador
 * @returns {Number} porcentaje calculado
 */
export const calculaPorcentaje = (paramNumerador, paramDenominador = 1) => {
  if (paramDenominador == 0 || paramDenominador == null) return 0.001.toFixed(2);
  const porcentaje = (paramNumerador / paramDenominador) * 100;
  return porcentaje.toFixed(2);
};

const COLOR = {
  PLOMO: "#cccccc",
  ROJO: "#ff0000",
  AMARILLO: "#ffff00",
  NARANJA: "#FFA500",
  VERDE: "#27ae60"
};

/**
 * showColorsByIndicadorParam
 * @param {number} RANGO_BAJO
 * @param {Number} RANGO_MEDIO
 * @param {Number} porcentaje
 * @returns {String} color de semáforo
 * usa tailwindcss
 * rojo: #e74c3c
 * ambar: #f39c12
 * verde: #27ae60
 */

export const semaforoByPorcentajeIndicador = (
  RANGO_SINDATO,
  RANGO_BAJO,
  RANGO_MEDIO,
  porcentaje
) => {
  if (Number(porcentaje) < Number(RANGO_SINDATO)) {
    return COLOR.ROJO;
  } else if (Number(porcentaje) < Number(RANGO_BAJO)) {
    return COLOR.NARANJA;
  } else if (Number(porcentaje) <= Number(RANGO_MEDIO)) {
    return COLOR.AMARILLO;
  } else {
    return COLOR.VERDE;
  }
};

/**
 * semaforoPorcentajeIndicadorHex
 * @param {Number} RANGO_BAJO
 * @param {Number} RANGO_MEDIO
 * @param {Number} porcentaje
 * @param {Number} denominador
 * @returns
 */
export const semaforoPorcentajeIndicadorHex = (
  RANGO_BAJO,
  RANGO_MEDIO,
  porcentaje,
  denominador
) => {
  if (denominador == 0) {
    return COLOR.ROJO;
  } else if (Number(porcentaje) < Number(RANGO_BAJO)) {
    return COLOR.AMARILLO;
  } else if (Number(porcentaje) <= Number(RANGO_MEDIO)) {
    return COLOR.NARANJA;
  } else {
    return COLOR.VERDE;
  }
};

/**
 * semaforoMapa
 * ===========
 * @param {Object} RANGO
 * @param {Number} denominador
 * @param {Number} porcentaje
 * @returns {String}
 */
export const semaforoMapa = (RANGO, denominador, porcentaje) => {
  if (denominador < 1) {
    return COLOR.ROJO;
  } else if (Number(porcentaje) <= Number(RANGO.RANGO_SINDATO)) {
    return COLOR.ROJO;
  } else if (Number(porcentaje) < Number(RANGO.RANGO_BAJO)) {
    return COLOR.NARANJA;
  } else if (Number(porcentaje) <= Number(RANGO.RANGO_MEDIO)) {
    return COLOR.AMARILLO;
  } else {
    return COLOR.VERDE;
  }
};

/**
 * semaforoMapaInverso
 * ===========
 * @param {Object} RANGO
 * @param {Number} denominador
 * @param {Number} porcentaje
 * @returns {String} color html
 */
export const semaforoMapaInverso = (RANGO, denominador, porcentaje) => {
  if (denominador < 1) {
    return COLOR.VERDE;
  } else if (Number(porcentaje) < Number(RANGO.RANGO_ALTO)) {
    return COLOR.VERDE;
  } else if (Number(porcentaje) < Number(RANGO.RANGO_MEDIO)) {
    return COLOR.AMARILLO;
  } else if (Number(porcentaje) <= Number(RANGO.RANGO_BAJO)) {
    return COLOR.NARANJA;
  } else {
    return COLOR.ROJO;
  }
};

export const generaLeyendaSemaforo = ({
  RANGO_SINDATO,
  RANGO_BAJO,
  RANGO_MEDIO,
  RANGO_ALTO
  // DENOMINADOR
}) => {
  return [
    {
      color: COLOR.VERDE,
      nombreColor: "verde",
      texto: RANGO_ALTO
    },
    {
      color: COLOR.AMARILLO,
      nombreColor: "amarillo",
      texto: RANGO_MEDIO
    },
    {
      color: COLOR.NARANJA,
      nombreColor: "naranja",
      texto: RANGO_BAJO
    },
    {
      color: COLOR.ROJO,
      nombreColor: "rojo",
      texto: RANGO_SINDATO
    }
    // {
    //   color: "#CCCCCC",
    //   nombreColor: "plomo",
    //   texto: "DENOMINADOR = 0"
    // }
  ];
};

export const generaLeyendaSemaforoInverso = ({
  RANGO_SINDATO,
  RANGO_BAJO,
  RANGO_MEDIO,
  RANGO_ALTO
  // DENOMINADOR
}) => {
  return [
    {
      color: COLOR.VERDE,
      nombreColor: "verde",
      texto: RANGO_ALTO
    },
    {
      color: COLOR.AMARILLO,
      nombreColor: "amarillo",
      texto: RANGO_MEDIO
    },
    {
      color: COLOR.NARANJA,
      nombreColor: "naranja",
      texto: RANGO_BAJO
    },
    {
      color: COLOR.ROJO,
      nombreColor: "rojo",
      texto: RANGO_SINDATO
    }
    // {
    //   color: "#CCCCCC",
    //   nombreColor: "plomo",
    //   texto: "DENOMINADOR = 0"
    // }
  ];
};

/**
 * semaforoMapaPorcentaje
 * ============================
 * Calcula solo por el porcentaje y ya no mas denominador
 * se usa en nutricional graficas
 * @param {Object} RANGO
 * @param {Number} porcentaje
 * @returns {String}
 */
export const semaforoMapaPorcentaje = (RANGO, porcentaje) => {
  if (Number(porcentaje) < Number(RANGO.RANGO_SINDATO)) {
    return COLOR.VERDE;
  } else if (Number(porcentaje) < Number(RANGO.RANGO_BAJO)) {
    return COLOR.AMARILLO;
  } else if (Number(porcentaje) <= Number(RANGO.RANGO_BAJO)) {
    return COLOR.NARANJA;
  } else {
    return COLOR.ROJO;
  }
};

/**
 * generaLeyendaSemaforoPorcentaje
 * Se usa en nutricional graficas
 * @param {Number} PORCENTAJE_BAJO
 * @param {Number} PORCENTAJE_MEDIO
 * @param {Number} PORCENTAJE_ALTO
 * @returns
 */
export const generaLeyendaSemaforoPorcentaje = ({
  PORCENTAJE_BAJO,
  PORCENTAJE_MEDIO,
  PORCENTAJE_ALTO
}) => {
  return [
    {
      color: COLOR.VERDE,
      nombreColor: "verde",
      texto: "No representa problema < " + PORCENTAJE_BAJO + " %"
    },
    {
      color: COLOR.AMARILLO,
      nombreColor: "amarillo",
      texto: "Problema leve < " + PORCENTAJE_MEDIO + " %"
    },
    {
      color: COLOR.NARANJA,
      nombreColor: "naranja",
      texto: "Problema moderado <= " + PORCENTAJE_ALTO + " %"
    }, // naranja
    { color: COLOR.ROJO, nombreColor: "rojo", texto: "Problema grave > " + PORCENTAJE_ALTO + " %" } // rojo
  ];
};

/**
 * calculaRangoPeriodos
 * Inserting string at position x of another string
 * https://stackoverflow.com/a/4364902/9868383
 * returns: 2021/12 al 2022/02
 */
export const calculaRangoPeriodos = (periodos = []) => {
  const periodoInicio = periodos[0]?.idperiodoinicio;
  const periodoFinal = periodos[periodos.length - 1]?.idperiodofinal;
  const position = 4;

  const periodoInicioSlash = joinPeriodos(periodoInicio, position);
  const periodoFinalSlash = joinPeriodos(periodoFinal, position);

  return `${periodoInicioSlash} al ${periodoFinalSlash}`;
};

const joinPeriodos = (periodo = "", position = 1) =>
  [periodo.slice(0, position), "/", periodo.slice(position)].join("");

export const generaArgumentoOptionsBar = (puntosArrayObject = []) => {
  return new Promise((resolve) => {
    var puntosEntrada = [];
    var numerador = [];
    var denominador = [];
    var tendencia = [];
    var max = 0;

    puntosArrayObject.forEach((e) => {
      puntosEntrada.push(e.idperiodo.substr(0, 4) + "/" + e.idperiodo.substr(4, 6));
      numerador.push(e.numerador);
      denominador.push(e.denominador);
      tendencia.push(e.numerador);

      if (parseInt(e.denominador) > max) max = parseInt(e.denominador);
    });

    resolve({
      puntosEntrada,
      numerador,
      denominador,
      tendencia,
      max
    });
  });
};

export const generaLabelPeriodoFormateado = (periodos = []) => {
  const result = [];

  periodos.map((p) => {
    result.push({
      idperiodoevaluacion: p.idperiodoevaluacion,
      texto: `${formateaIdsPeriodoHuman(p.idperiodoinicio)} - ${formateaIdsPeriodoHuman(
        p.idperiodofinal
      )}`
    });
  });

  return result;
};

/**
 * formateaIdsPeriodoHuman
 * @param {Number} idperiodo
 * @returns {String}
 * Ejemplo:
 * Input: 202201
 * Output: ENE 2021
 */
export const formateaIdsPeriodoHuman = (idperiodo) => {
  const meses = [
    "ENE",
    "FEB",
    "MAR",
    "ABR",
    "MAY",
    "JUN",
    "JUL",
    "AGO",
    "SET",
    "OCT",
    "NOV",
    "DIC"
  ];

  const month = idperiodo.substr(4, 6); // 202204 -> 04
  const year = idperiodo.substr(0, 4);

  let indexMonth = 0;

  if (month < 10) {
    indexMonth = month[1] - 1; // 04 -> 3
  } else {
    indexMonth = month - 1; // 12 -> 11
  }

  return `${meses[indexMonth]} ${year}`;
};
