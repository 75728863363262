<template>
  <div>
    <p>
      En virtud del cumplimiento de la Ley N° 29733 – Ley de Protección de Datos Personales y del
      Sistema de Gestión de Seguridad de la Información, el usuario del sistema SIVINUR acepta y
      reconoce que el software informático es propiedad industrial o intelectual de Gobierno
      Regional de Tacna y queda expresamente prohibido su uso por terceros no autorizados. El
      usuario por el trabajo y las funciones que realiza en el sistema, conocerá este acuerdo y se
      encontrará igualmente obligado a mantener confidencialidad respecto de la información y no
      está disponible para uso personal y no puede ser utilizado, copiado, reproducido, distribuido,
      transmitido, difundido, vendido, licenciado o, de cualquier otra forma, explotado para
      cualquier otro fin sin el consentimiento previo y por escrito de sus respectivos propietarios.
      El usuario acepta no utilizar, desactivar o interferir con los recursos relacionados con la
      seguridad del sitio web y de la aplicación.
    </p>
    <br />
    <p>
      Sin embargo, el usuario podrá imprimir el material contenido en este sitio web, siempre que el
      mismo no se modifique, sea para uso personal y no comercial, y mantenga la indicación de la
      fuente y de los derechos de autor, y siempre que no se elimine ninguna otra indicación de
      propiedad.
    </p>
    <br />
    <p>
      Nada en este sitio web deberá interpretarse como una concesión de cualquier tipo de licencia u
      otra autorización de uso a sus usuarios. El sitio web puede contener imágenes u otros
      materiales cuyos derechos de autor pertenecen a terceros, tal como proveedores u otras
      entidades que contribuyan a su creación o mantenimiento (como, por ejemplo, fotos de banco de
      imágenes). A pesar de que los datos personales proporcionados a SIVINUR están protegidos de
      acuerdo a lo definido en la Política de privacidad y uso de datos que aparecen en los
      presentes Términos y condiciones de uso, cualquier información u otro contenido que el usuario
      transmita a través de este sitio web, ya sea mediante correo electrónico, envío de archivos o
      de algún otro modo, se considerará como no confidencial y no reservado.
    </p>
    <br />
    <p>
      Por lo tanto, se obliga a no divulgar, revelar, comunicar, transmitir, grabar, duplicar,
      copiar o cualquier otra forma de reproducir, sin la autorización expresa y por escrito del
      Gobierno Regional de Tacna; la información y documentación a que tengo acceso. Tomaremos todas
      las acciones que fuesen razonables para impedir la divulgación de cualquier información a
      cualquier persona, sin el previo consentimiento del Gobierno Regional de Tacna.
    </p>
    <br />
    <p>
      En caso de incumplimiento, se somete a las responsabilidades de índole administrativa, penal y
      civil conforme a Ley. Las obligaciones y derechos inmersos en el presente acuerdo de
      confidencialidad estarán vigentes a partir de la fecha que se inicia en el uso del sistema,
      durante el tiempo que dure esta relación y después de la fecha en que se haya dado por
      terminada la relación con el uso sistema, sin importar la razón de la misma.
    </p>
    <br />
    <p>
      A los efectos previstos en este Compromiso, se define como “Información confidencial” a toda
      aquella información, ya sea técnica, financiera, comercial, personal o de cualquier otro
      carácter, que sea suministrada y/o comunicada por el Gobierno Regional de Tacna, mediante
      palabra, por escrito o por cualquier otro medio o soporte, tangible, actualmente conocido o
      que posibilite el estado de la técnica en el futuro.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
