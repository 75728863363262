<template>
  <section
    class="layout-inicio flex flex-col bg-gray-100 w-full min-h-screen items-center justify-between"
  >
    <!-- :style="{ backgroundImage: 'url(\'' + require('@/assets/background-3.jpg') + '\')' }" -->
    <main class="mb-auto w-full">
      <slot></slot>
    </main>

    <div class="bg-gray-700 w-full pb-12">
      <div class="mx-auto container px-6 flex flex-col items-center justify-center">
        <div class="text-black flex flex-col md:items-center pt-3">
          <div class="my-4 text-base text-gray-200">
            <ul class="md:flex items-center">
              <a
                target="_blank"
                href="http://www.diresatacna.gob.pe/nuevo/"
                class="md:mr-6 cursor-pointer pt-4 lg:py-0"
              >
                DIRESA
              </a>
              <a
                target="_blank"
                href="https://www.redsaludtacna.gob.pe/"
                class="md:mr-6 cursor-pointer pt-4 lg:py-0"
              >
                RED DE SALUD TACNA
              </a>
              <router-link to="/login" class="md:mr-6 cursor-pointer pt-4 lg:py-0">
                SIVINUR
              </router-link>
            </ul>
          </div>
          <div class="text-sm text-gray-300 mb-6">
            <p>
              © 2023 SIVINUR
              <a target="_blank" href="http://www.regiontacna.gob.pe/"
                >GOBIERNO REGIONAL DE TACNA</a
              >
              - DIRESA Y RED DE SALUD
            </p>
            <p class="text-center text-xxs">⭐⭐⭐⭐⭐</p>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=sivinur.app.cliente"
            ><img class="w-40" :src="GetItPlayStore" alt="Descargar aplicación desde playstore"
          /></a>
        </div>
      </div>
    </div>

    <!-- ./footer -->
  </section>
</template>

<script>
import GetItAppStore from "@/assets/get-it-appstore.svg";
import GetItPlayStore from "@/assets/google-play-badge.png";

export default {
  setup() {
    // console.log(location.host);

    return {
      GetItAppStore,
      GetItPlayStore
      // descargarApk
    };
  }
};
</script>

<style scoped lang="less"></style>
