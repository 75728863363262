import { post, put } from "./base";
import publicIp from "public-ip";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE_RESTABLECER = "/api/auth/restablecer";
const API_SERVICE_CORREO = "/api/auth/correo";
const API_SERVICE_RECOVER = "/api/auth/recover";
const API_SERVICE_ARRAY = "/api/ServiceArray/V1";
const API_SERVICE = "/api/Service/V1";
const API_CAMBIAR_CONTRA = "/api/auth/cambiar_contra";
const API_SERVICE_ARRAY_BY_ROWS = "/api/ServiceArrayByRows/V1";
const API_PUBLIC = "/api/Prueba/V1/query";

export default {
  login: (credentials) => post("/api/auth/login", credentials),

  logout: (payload) => {
    return post("/api/auth/logout", payload);
  },
  getModulos: () => post("/api/auth/roles"),

  forgot: async (payload) => {
    var response = {
      _type: 1,
      _table: "SMTP",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "_correo",
          value: payload.correo,
          type: "string"
        },
        {
          name: "TIME_EXPIRE",
          value: payload.isFromAdmin,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_RESTABLECER, response);
  },

  recover: async (payload) => {
    var response = {
      _type: 1,
      _table: "SMTP",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "_correo",
          value: payload.correo,
          type: "string"
        },
        {
          name: "_codigo",
          value: payload.codigo,
          type: "string"
        },
        {
          name: "_password",
          value: payload.password,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_RECOVER, response);
  },

  getAll: async (payload) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_usuario",
          type: "string"
        }
      ]
    };

    if (payload.filter !== "all") {
      const { filter, search } = payload;

      request._petitions.push({
        name: "@filtros_AND",
        value: "e.idestado",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `${filter}`,
        type: "string"
      });

      request._petitions.push({
        name: "@filtros_OR",
        value: "usuario|u.nombre|u.apellido|u.correo|u.celular",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%|%${search}%|%${search}%|%${search}%`,
        type: "string"
      });
    } else if (payload.search) {
      const { search } = payload;

      request._petitions.push({
        name: "@filtros_OR",
        value: "usuario|u.nombre|u.apellido|u.correo|u.celular",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%|%${search}%|%${search}%|%${search}%`,
        type: "string"
      });
    }

    return post(API_SERVICE_QUERY, request);
  },

  deleteMany: async (payload) => {
    const request = {
      _type: 3,
      _table: "usuario",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "@ids",
          value: payload.join("|"),
          type: "string"
        },
        {
          name: "eliminado",
          value: "1",
          type: "int"
        }
      ]
    };
    return put(API_SERVICE_ARRAY, request);
  },

  deleteManyRoles: async (arrayIdsDelete = [], idusuario = "") => {
    const request = {
      _type: 3,
      _table: "usuariorol",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "idusuariorol|idusuario",
          value: arrayIdsDelete.map((op) => op + "," + idusuario).join("|"), //genera: 68,39|69,39
          type: "string"
        },
        {
          name: "eliminado",
          value: arrayIdsDelete.map(() => "1").join("|"), // genera: 1|1
          type: "string"
        }
      ]
    };

    return put(API_SERVICE_ARRAY_BY_ROWS, request);
  },

  getDocumento: async (payload) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_documento",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "documentoregistro",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${payload.value}%`,
          type: "string"
        }
      ]
    };
    return post(API_SERVICE_QUERY, request);
  },

  getUserLog: async () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_user_log",
          type: "string"
        },
        {
          name: "@FECHANI",
          value: "2021-10-09",
          type: "string"
        },
        {
          name: "@FECHAFIN",
          value: "2021-11-02",
          type: "string"
        }
      ]
    };
    return post(API_SERVICE_QUERY, request);
  },

  getEstados: async () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_estados",
          type: "string"
        },
        {
          name: "@filtros",
          value: "activo",
          type: "string"
        },
        {
          name: "@buscar",
          value: "1",
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  addOne: async (payload) => {
    const request = {
      _type: 1,
      _table: "usuario",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "usuario",
          value: `${payload.usuario}`,
          type: "string|unique"
        },
        {
          name: "nombre",
          value: `${payload.nombre}`,
          type: "string"
        },
        {
          name: "correo",
          value: `${payload.correo}`,
          type: "string|unique"
        },
        {
          name: "apellido",
          value: `${payload.apellido}`,
          type: "string"
        },
        {
          name: "celular",
          value: `${payload.celular}`,
          type: "string"
        },
        {
          name: "constrasenia",
          value: `${payload.constrasenia}`,
          type: "password"
        },
        {
          name: "idestado",
          value: `${payload.idestado}`,
          type: "int"
        },
        {
          name: "idestablecimiento",
          value: `${payload.idestablecimiento}`,
          type: "string"
        }
      ]
    };

    if (payload.format_fecha_exp) {
      request._petitions.push({
        name: "fechaexpirado",
        value: `${payload.format_fecha_exp}`,
        type: "string"
      });
    }

    return post(API_SERVICE, request);
  },

  editOne: async (payload, idusuario, isUnique) => {
    const request = {
      _type: 3,
      _table: "usuario",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "usuario",
          value: `${payload.usuario}`,
          type: "string" // el unique se valida si ya existe id
        },
        {
          name: "nombre",
          value: `${payload.nombre}`,
          type: "string"
        },
        {
          name: "correo",
          value: `${payload.correo}`,
          type: isUnique ? "string|unique" : "string"
        },
        {
          name: "apellido",
          value: `${payload.apellido}`,
          type: "string"
        },
        {
          name: "celular",
          value: `${payload.celular}`,
          type: "string"
        },
        {
          name: "idestado",
          value: `${payload.idestado}`,
          type: "int"
        },
        {
          name: "idestablecimiento",
          value: `${payload.idestablecimiento}`,
          type: "int"
        }

        // {
        //   name: "fechaexpirado",
        //   value: `${payload.format_fecha_exp}`,
        //   type: "string"
        // }
      ]
    };

    if (payload.idestado == 1) {
      request._petitions.push({
        name: "intento",
        value: 0,
        type: "string"
      });
    }

    if (payload.format_fecha_exp) {
      request._petitions.push({
        name: "fechaexpirado",
        value: `${payload.format_fecha_exp}`,
        type: "string"
      });
    }

    return put(API_SERVICE + "/" + idusuario, request);
  },

  changeState: async (idusuario, idestado) => {
    const request = {
      _type: 3,
      _table: "usuario",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "idestado",
          value: `${idestado}`,
          type: "int"
        }
      ]
    };

    return put(API_SERVICE + "/" + idusuario, request);
  },

  resetIntentos: async (idusuario) => {
    const request = {
      _type: 3,
      _table: "usuario",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "intento",
          value: 0,
          type: "string"
        }
      ]
    };

    return put(API_SERVICE + "/" + idusuario, request);
  },

  editCelularCorreo: async (payload, idusuario, isUnique) => {
    const request = {
      _type: 3,
      _table: "usuario",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "correo",
          value: `${payload.correo}`,
          type: isUnique ? "string|unique" : "string"
        },
        {
          name: "celular",
          value: `${payload.celular}`,
          type: "string"
        }
      ]
    };

    return put(API_SERVICE + "/" + idusuario, request);
  },

  correo: async (payload) => {
    const request = {
      _type: 2,
      _table: "SMTP",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "_correo",
          value: payload.correo,
          type: "string"
        },
        {
          name: "_usuario",
          value: payload.usuario,
          type: "string"
        },
        {
          name: "_password",
          value: payload.constrasenia,
          type: "string"
        }
      ]
    };
    return post(API_SERVICE_CORREO, request);
  },

  getAllRoles: async () => {
    const request = {
      _type: 1,
      _table: "undefiend",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_rol",
          type: "string"
        }
      ]
    };
    return post(API_SERVICE_QUERY, request);
  },

  getUsuarioRolById: async (idUsuario) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_usuariorol_idusuario",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "idusuario",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: idUsuario,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getPerfilesById: async (idusuario) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_roles_idusuario",
          type: "string"
        },
        {
          name: "@IDUSUARIO",
          value: idusuario,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  cambiarContra: (payload) => {
    return post(API_CAMBIAR_CONTRA, payload);
  },

  asignRoles: async (payload) => {
    const request = {
      _type: 2,
      _table: "usuariorol",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "campos",
          value: "idrol,idusuario",
          type: "array"
        },
        {
          name: "values",
          value: payload,
          type: "array"
        }
      ]
    };

    return post(API_SERVICE_ARRAY, request);
  },

  userLogAdd: async (modulo, opcion, establecimiento = "") => {
    const request = {
      _type: 2,
      _table: "userlog_sivinur",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "modulo",
          value: modulo,
          type: "string"
        },
        {
          name: "submodulo",
          value: opcion,
          type: "string"
        },
        {
          name: "ippublica",
          value: await publicIp.v4(),
          type: "string"
        },
        {
          name: "establecimiento",
          value: establecimiento,
          type: "string"
        }
      ]
    };
    return post(API_SERVICE, request);
  },

  getFechaImportacion: async (nombreTabla) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _ip: await publicIp.v4(),
      _petitions: [
        {
          name: "_script",
          value: "web_migr_select_fechaingreso_importacion",
          type: "string"
        },
        {
          name: "@TABLA",
          value: `%${nombreTabla}%`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getFechaActualizacion: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "q_web_padron_nominal_fechaactualizacion",
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getParametroxDenominacion: (parametro) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_parametro_by_denominacion",
          type: "string"
        },
        {
          name: "@PARAMETRO",
          value: parametro,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  }
};
